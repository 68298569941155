import ReactGA from 'react-ga4';
import config from '../../config/config';

function initializeReactGA() {
   ReactGA.initialize(config.GoogleAnalitycsID);
}

function SetEvent(category, action, label) {
   initializeReactGA();
   ReactGA.event({
      category: category,
      action: action,
      label: label,
   });
}

export { SetEvent };