import React, { Component } from 'react';

export default class ThemeButton extends Component {
	render() {
		return (
			<>
				{/* <a href="https://1.envato.market/3zZ9y" className="bt-buy-now theme-btn"><i className="ti-shopping-cart"></i><span>Buy Now</span></a> */}
				<a href="https://wa.me/6285175436661?text=Hello%20Admin%2C%20I%20have%20a%20question%20regarding%20your%20product%20?" className="bt-support-now theme-btn"><i className="ti-headphone-alt"></i><span>Support</span></a>
			</>
		)
	}
}
