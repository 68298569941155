import React, { Component } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
// import Header from './../../Layout/Header';
// import Footer from './../../Layout/Footer';
// import PageTitle from './../../Layout/PageTitle';
// import { OrderDetail } from './../Login';
import { SetEvent } from '../../utils/analitycs/google-analitycs';
import { CTAInquiryUrl } from '../../utils/cta/cta';


const ItemListProduct = (props) => {
   const { productName, productImage, cta, keyName, data } = props;


   return (
      <>
         <div className="col-lg-4 col-md-6 col-sm-12 col-6">
            <div className="item-box m-b10">
               <div className="item-img">
                  <img src={productImage} alt={productName} className='item-product-img' />
                  <div className="item-info-in center">
                     <ul>
                        <li><Link
                           to={{
                              pathname: `/product/${keyName}`,
                              data: data,
                           }}
                           onclick={() => { SetEvent('Product', 'Click', productName) }}
                        ><i className="ti-eye mr-2"></i></Link></li>
                        <li><a href={CTAInquiryUrl(cta)} onClick={() => SetEvent('inquiry', 'direct_whatsapp', productName)}><i className="ti-headphone-alt ml-2"></i></a></li>
                     </ul>
                  </div>
               </div>
               <div className="item-info text-center text-black p-a10">
                  <h6 className="item-title font-weight-500">
                     <Link
                        to={{
                           pathname: `/product/${keyName}`,
                           data: data,
                        }}
                     >
                        {productName}
                     </Link>
                  </h6>
               </div>
            </div>
         </div>
      </>
   )
   // }
}
export default ItemListProduct;