import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ThemeButton from './Element/ThemeButton/ThemeButton';

import LandingPage from './Pages/LandingPage';
import Error404 from './Pages/Error/Error404';
import AboutUs from './Pages/Aboutus';
import Faqs from './Pages/Faqs';
import Product from './Pages/Product/index';
import ProductDetail from './Pages/ProductDetail';

import ScrollToTop from './Element/ScrollToTop';
import config from '../config/config';


class Markup extends Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={LandingPage} />
                        <Route path='/about-us' exact component={AboutUs} />
                        <Route path='/product' exact component={Product} />
                        <Route path='/faqs' exact component={Faqs} />
                        <Route path='/not-found' exact component={Error404} />
                        <Route path='/product/:keyName' exact component={ProductDetail} />
                        <Route path='*' component={Error404} />
                    </Switch>
                </div>
                <ScrollToTop />
                <ThemeButton />
            </BrowserRouter>
        )
    }

}

export default Markup;