
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

//Images
import farm1 from './../../../assets/images/about/farm1.jpg';
import farm2 from './../../../assets/images/about/farm2.jpg';
import farm3 from './../../../assets/images/about/farm3.jpg';
import farm4 from './../../../assets/images/about/farm4.jpg';

function Para() {
   return (
      <>
         <div className="about-box p-lr30">

            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
               when an unknown printer took a galley of type and scrambled it to make a type specimen book.
               It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
               It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
         </div>
      </>
   )
}

function ParagraphTab1() {
   return (
      <>
         <div className="about-box p-lr30">
            <p>Our products reflect the exceptional skill of Indonesia's artisans, fishermen, and farmers. We prioritize sourcing high-quality, naturally grown agricultural products and sustainably harvested fisheries. Each item undergoes rigorous inspection to meet international standards, ensuring that only the best reaches our customers.</p>
         </div>
      </>
   )
}

function ParagraphTab2() {
   return (
      <>
         <div className="about-box p-lr30">
            <p>We are committed to protecting Indonesia's natural ecosystems through sustainable sourcing and eco-friendly practices. Our supply chain supports local farmers, fishermen, and artisans, helping them thrive while preserving their traditional ways. We adopt green logistics and sustainable packaging, minimizing our impact on the environment.</p>
         </div>
      </>
   )
}

function ParagraphTab3() {
   return (
      <>
         <div className="about-box p-lr30">
            <p>Our products reflect the rich cultural heritage and wisdom of Indonesia's diverse regions, from handcrafted goods to local delicacies. We ensure fair trade and ethical practices, respecting the communities and traditions behind our products. Each item carries the authenticity of Indonesia's natural beauty, connecting consumers with the heart of the archipelago.</p>
         </div>
      </>
   )
}

function ParagraphTab4() {
   return (
      <>
         <div className="about-box p-lr30">
            <p>With our global distribution network, we bring Indonesia's treasures to international markets, from Europe to North America and beyond. By partnering with small enterprises, we empower local communities, creating economic opportunities while promoting sustainable development. We prioritize our clients' needs, offering personalized service, reliable delivery, and a commitment to long-term partnerships.</p>
         </div>
      </>
   )
}

const ChoseUs = (props) => {
   const [activeTab, setActiveTab] = useState('1');

   const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab);
   }

   return (
      <Fragment>
         <div className="container-fluid ">
            <div className="dlab-tabs choseus-tabs">
               <ul className="nav row justify-content-center w-100 " id="myTab" role="tablist">
                  {/* <li>
							<a className= {classnames({ active : activeTab === '1' }) + ' className if avialble'}  onClick={() => { toggle('1'); }}	
							><span className="title-head">Cost Effectiveness</span></a>
						</li> */}
                  <li>
                     <Link to={"#"} className={classnames({ active: activeTab === '1' }) + ''} onClick={() => { toggle('1'); }}><span className="title-head">Unparalleled Product Quality</span></Link>
                  </li>
                  <li>
                     <Link to={"#"} className={classnames({ active: activeTab === '2' }) + ''} onClick={() => { toggle('2'); }}><span className="title-head">Sustainably Sourced & Eco-Friendly</span></Link>
                  </li>
                  <li>
                     <Link to={"#"} className={classnames({ active: activeTab === '3' }) + ''} onClick={() => { toggle('3'); }}><span className="title-head">Cultural Heritage & Authenticity</span></Link>
                  </li>
                  <li>
                     <Link to={"#"} className={classnames({ active: activeTab === '4' }) + ''} onClick={() => { toggle('4'); }}><span className="title-head">Global Reach, Local Impact</span></Link>
                  </li>
                  {/* <li>
                     <Link to={"#"} className={classnames({ active: activeTab === '3' }) + ''} onClick={() => { toggle('3'); }}><span className="title-head">Product</span></Link>
                  </li> */}
                  {/* <li>
                     <Link to={"#"} className={classnames({ active: activeTab === '4' }) + ''} onClick={() => { toggle('4'); }}><span className="title-head">Pre-Built Packages</span></Link>
                  </li> */}
               </ul>
            </div>
         </div >
         <div className="container">
            <div className="tab-content chosesus-content">
               <div id="cost" className="tab-pane active">
                  <TabContent activeTab={activeTab}>
                     <TabPane tabId="1">
                        <div className="row p-tb50">
                           <div className="col-lg-6 m-b10 col-md-4 about-img " >
                              <img src={farm1} alt="" />
                           </div>
                           <div className="col-lg-6 col-md-8 text-white">
                              <div className="p-l30 " >
                                 <h4 className="font-weight-300">Unparalleled Product Quality</h4>
                                 <ul className="list-checked primary">
                                    <li><span><b>Artisanal Craftsmanship:</b> Every product we offer is a testament to the skill and dedication of Indonesia's artisans, fishermen, and farmers.</span></li>
                                    <li><span><b>Premium, Natural Resources:</b> We source only the finest, naturally grown agricultural products and sustainably harvested fisheries.</span></li>
                                    <li><span><b>Strict Quality Control:</b> Each product is carefully inspected to meet international standards and ensure only the best reaches our customers.</span></li>
                                 </ul>
                              </div>
                              <ParagraphTab1 />
                           </div>
                        </div>
                     </TabPane>
                     <TabPane tabId="2">
                        <div className="row p-tb50">
                           <div className="col-lg-6 col-md-4 about-img">
                              <img src={farm2} alt="" />
                           </div>
                           <div className="col-lg-6 col-md-8 text-white">
                              <div className="p-l30">
                                 <h4 className="font-weight-300"> Sustainably Sourced & Eco-Friendly</h4>
                                 <ul className="list-checked primary">
                                    <li><span><b>Environmental Responsibility:</b> We are committed to protecting Indonesia's natural ecosystems through sustainable sourcing and eco-friendly practices.</span></li>
                                    <li><span><b>Support for Local Communities:</b> Our supply chain supports local farmers, fishermen, and artisans, helping them thrive while preserving their traditional ways.</span></li>
                                    <li><span><b>Minimized Carbon Footprint:</b> We adopt green logistics and sustainable packaging, minimizing our impact on the environment.</span></li>
                                 </ul>
                              </div>
                              <ParagraphTab2 />
                           </div>
                        </div>
                     </TabPane>
                     <TabPane tabId="3">
                        <div className="row p-tb50">
                           <div className="col-lg-6 col-md-4 about-img">
                              <img src={farm3} alt="" />
                           </div>
                           <div className="col-lg-6 col-md-8 text-white">
                              <div className="p-l30">
                                 <h4 className="font-weight-300">Cultural Heritage & Authenticity</h4>
                                 <ul className="list-checked primary">
                                    <li><span><b>Celebrating Indonesian Culture:</b> Our products reflect the rich cultural heritage and wisdom of Indonesia’s diverse regions, from handcrafted goods to local delicacies.</span></li>
                                    <li><span><b>Ethically Produced:</b> We ensure fair trade and ethical practices, respecting the communities and traditions behind our products.</span></li>
                                    <li><span><b>Authenticity Guaranteed:</b> Each product carries the authenticity of Indonesia’s natural beauty, connecting consumers with the heart of the archipelago.</span></li>
                                 </ul>
                              </div>
                              <ParagraphTab3 />
                           </div>
                        </div>
                     </TabPane>
                     <TabPane tabId="4">
                        <div className="row p-tb50">
                           <div className="col-lg-6 col-md-4 about-img">
                              <img src={farm4} alt="" />
                           </div>
                           <div className="col-lg-6 col-md-8 text-white">
                              <div className="p-l30">
                                 <h4 className="font-weight-300">Global Reach, Local Impact</h4>
                                 <ul className="list-checked primary">
                                    <li><span><b>Connecting Indonesia to the World:</b> With our global distribution network, we bing Indonesia's treasures to international markets, from Europe to North America and beyond.</span></li>
                                    <li><span><b>Positive Social Impact:</b> By partnering with small enterprises, we empower local communities, creating economic opportunities while promoting sustainable development.</span></li>
                                    <li><span><b>Customer-Centric Approach:</b> We prioritize our clients' needs, offering personalized service, reliable delivery, and a commitment to long-term partnerships.</span></li>
                                 </ul>
                              </div>
                              <ParagraphTab4 />
                           </div>
                        </div>
                     </TabPane>

                  </TabContent>
               </div>
            </div>
         </div>
      </Fragment >
   )

}
export default ChoseUs;