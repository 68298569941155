export const WarrantyService = [
   {
      icon: <i className="ti-check-box" />,
      title: 'Transparency And Trust',
      description: 'We are committed to providing our clients with the highest level of transparency and trust.'
   },
   {
      icon: <i className="ti-user" />,
      title: 'Expert Team',
      description: 'Our team is driven by a shared commitment to excellence, ensuring we deliver results that exceed expectations.'
   },
   {
      icon: <i className="ti-headphone-alt" />,
      title: '24/7 SUPPORT',
      description: 'Our team is available 24/7 to answer any questions you may have. We are here to help!'
   },
];