import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, ListGroup, useAccordionToggle } from 'react-bootstrap';
import Header from './../../Layout/Header';
import Footer from './../../Layout/Footer';
import PageTitle from './../../Layout/PageTitle'
// import RangeSlider from './RangeSlider';

import bnr from './../../../assets/images/banner/bnr5.jpg';
import bgNarualIndonesia from './../../../assets/images/background/bg-natural-indonesia.jpg';
import ItemListProduct from '../../Element/ItemListProduct';
import { GetAllCategoryProduct, GetAllProducts } from '../../../repository/resource';
import { OrderService } from '../../Element/Section/OrderService';
import Spinner from '../../Element/Spinner';



class Product extends Component {
   constructor(props) {
      super(props);
      this.props = props
      this.state = {
         dataProduct: [],
         dataFilterProduct: [],
         dataProductCategories: [
            {
               title: "All Product",
               key: 0
            },
         ],
         isLoadingProduct: true,
         isLoadingCategoryProduct: true
      }
   }
   componentDidMount() {

      //Sidebar open and Close 
      // var Closeicon = document.querySelector('.filter-btn');
      // const products = await GetAllCategoryProduct(this)
      // console.log('DATA-products', products)
      // const categoryProduct = await GetAllCategoryProduct()

      // this.setState({
      //    ...this.state,
      //    dataProductCategories: { ...this.state.dataProductCategories, ...products },
      //    // dataProductCategories: categoryProduct
      // })

      this.handleGetProductCategories()
      this.handleGetProductList()
      var sidebaropen = document.querySelector('.filter-left');
      var Closeicon = [].slice.call(document.querySelectorAll('.filter-btn'));
      for (var y = 0; y < Closeicon.length; y++) {
         Closeicon[y].addEventListener('click', toggleFunc);
      }

      function toggleFunc() {
         if ((" " + sidebaropen.className + " ").replace(/[\n\t]/g, " ").indexOf(" filter-on ") > -1) {
            sidebaropen.classList.remove('filter-on');
            sidebaropen.classList.add('filter-off');
         } else {
            sidebaropen.classList.add('filter-on');
            sidebaropen.classList.remove('filter-off');
         }
      }

   }

   async handleGetProductList() {
      const products = await GetAllProducts(this)

      this.setState({
         dataProduct: products,
         isLoadingProduct: false
      })
   }

   async handleGetProductCategories() {
      const category = []
      const categories = await GetAllCategoryProduct(this)

      categories.forEach(p => {
         var c = {
            display: p.title,
            key: p.id
         }
         category.push(c)
      });

      this.setState({
         dataProductCategories: category,
         isLoadingCategoryProduct: false
      })
   }

   handleFilterProductCategories(keyCategory) {
      if (!keyCategory) {
         this.setState({
            dataFilterProduct: []
         })
         return
      }

      const newData = this.state.dataProduct.filter((data) => data?.product_category === keyCategory)
      this.setState({
         dataFilterProduct: newData
      })
   }




   render() {

      const CustomToggle = ({ children, eventKey, keyCategory, display }) => {
         const decoratedOnClick = useAccordionToggle(
            eventKey,
            (e) => {
               this.handleFilterProductCategories(keyCategory)
            }
         );

         return (
            <li className='list-product-categories-text' onClick={decoratedOnClick}>{display}</li>
         );
      }
      const { dataProduct, dataFilterProduct, dataProductCategories } = this.state
      return (
         <>
            <Header
               title="Product"
               metaDescription="Our Product."
            />

            <div className="page-content bg-white">
               {/* <!-- inner page banner --> */}
               <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bgNarualIndonesia + ")" }}>
                  {/* <PageTitle motherMenu="Shop Columns Sidebar" activeMenu="Shop Columns Sidebar" /> */}
                  <PageTitle motherMenu="Product" activeMenu="Product" />
               </div>
               {/* <!-- inner page banner END --> */}
               {/* <!-- contact area --> */}
               <div className="section-full content-inner">
                  {/* <!-- Product --> */}
                  <div className="container">
                     <div className="row sp10-sm">
                        <div className="col-lg-12 col-md-12 col-sm-12 filter-bar">
                           <div className="filter-mobile">
                              {/* <div className="filter-bx">
                                 <select>
                                    <option>Sort By</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                 </select>
                              </div> */}
                              <div className="filter-bx">
                                 <Link to={"#"} className="filter-btn black"><i className="fa fa-filter"></i> Fillter</Link>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 sticky-top filter-bar">
                           <aside className="side-bar shop-categories filter-left "  >
                              <Link to={"#"} className="filter-btn close black"><i className="fa fa-close"></i></Link>
                              <div className="widget recent-posts-entry">
                                 <div className="dlab-accordion advanced-search toggle" id="accordion1">
                                    <Accordion defaultActiveKey="0">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="0">Product categories</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          {
                                             this.state.isLoadingCategoryProduct ? <Spinner /> :
                                                <Accordion.Collapse eventKey="0">
                                                   <div className="acod-content">
                                                      <div className="widget widget_services">
                                                         <ul>
                                                            {
                                                               dataProductCategories.length > 0 && dataProductCategories.map((c, i) => {
                                                                  return (
                                                                     <CustomToggle key={i} keyCategory={c.key} display={c.display}>{"▶ " + c.display}</CustomToggle>
                                                                  )
                                                               })
                                                            }
                                                         </ul>
                                                      </div>
                                                   </div>
                                                </Accordion.Collapse>
                                          }
                                       </div>
                                    </Accordion>
                                    {/* <Accordion defaultActiveKey="1">
                                       <div className="acod-head">
                                          <h5 className="acod-title">
                                             <Accordion.Toggle as={Link} variant="link" eventKey="1">Price Range</Accordion.Toggle>
                                          </h5>
                                       </div>
                                       <Accordion.Collapse eventKey="1" >
                                          <div className="acod-content">
                                             <div className="price-slide-2 range-slider">
                                                <div className="price">
                                                   <input type="text" id="amount-2" className="amount" readOnly="" value="$15 - $100" />
                                                   <div id="slider-range-2">
                                                      <RangeSlider />
                                                   </div>

                                                </div>
                                             </div>
                                          </div>
                                       </Accordion.Collapse>
                                    </Accordion> */}
                                    {/* <Accordion defaultActiveKey="2">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="2">COLOR</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="2" >
                                             <div className="acod-content">
                                                <h6>Select the color</h6>
                                                <div className="btn-group product-item-color" data-toggle="buttons">
                                                   <label className="btn bg-red active">
                                                      <input name="options" id="option1" checked="" type="radio" />
                                                   </label>
                                                   <label className="btn bg-pink">
                                                      <input name="options" id="option2" type="radio" />
                                                   </label>
                                                   <label className="btn bg-yellow">
                                                      <input name="options" id="option3" type="radio" />
                                                   </label>
                                                   <label className="btn bg-blue">
                                                      <input name="options" id="option4" type="radio" />
                                                   </label>
                                                   <label className="btn bg-green">
                                                      <input name="options" id="option5" type="radio" />
                                                   </label>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                    {/* <Accordion defaultActiveKey="3">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="3">Size</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="3" >
                                             <div className="acod-content">
                                                <h6>Product Size</h6>
                                                <div className="btn-group product-item-size" data-toggle="buttons">
                                                   <label className="btn active">
                                                      <input name="options" id="option6" checked="" type="radio" />XS
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option7" type="radio" /> LG
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option8" type="radio" /> MD
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option9" type="radio" /> SM
                                                   </label>
                                                   <label className="btn">
                                                      <input name="options" id="option10" type="radio" /> Xl
                                                   </label>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                    {/* <Accordion defaultActiveKey="4">
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="4">VENDOR</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="4">
                                             <div className="acod-content">
                                                <div className="product-brand">
                                                   <div className="search-content">
                                                      <input id="seating1" type="checkbox" />
                                                      <label htmlFor="seating1" className="search-content-area">Gap</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating2" type="checkbox" />
                                                      <label htmlFor="seating2" className="search-content-area">Guess</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating3" type="checkbox" />
                                                      <label htmlFor="seating3" className="search-content-area">Lacoste</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating4" type="checkbox" />
                                                      <label htmlFor="seating4" className="search-content-area">Levi's</label>
                                                   </div>
                                                   <div className="search-content">
                                                      <input id="seating5" type="checkbox" />
                                                      <label htmlFor="seating5" className="search-content-area">Polo</label>
                                                   </div>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                    {/* <Accordion >
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" className="collapsed" eventKey="5">POPULAR TAGS</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="5">
                                             <div className="acod-content">
                                                <div className="widget_tag_cloud radius">
                                                   <div className="tagcloud"> <Link to={"#"} className="mr-1">Design</Link>
                                                      <Link to={"#"} className="mr-1">User interface</Link>
                                                      <Link to={"#"} className="mr-1">SEO</Link>
                                                      <Link to={"#"} className="mr-1">WordPress</Link>
                                                      <Link to={"#"} className="mr-1">Development</Link>
                                                      <Link to={"#"} className="mr-1">Joomla</Link>
                                                      <Link to={"#"} className="mr-1">Design</Link>
                                                      <Link to={"#"} className="mr-1">User interface</Link>
                                                      <Link to={"#"} className="mr-1">SEO</Link>
                                                      <Link to={"#"} className="mr-1">WordPress</Link>
                                                      <Link to={"#"} className="mr-1">Development</Link>
                                                      <Link to={"#"} className="mr-1">Joomla</Link>
                                                      <Link to={"#"} className="mr-1">Design</Link>
                                                      <Link to={"#"} className="mr-1">User interface</Link>
                                                      <Link to={"#"} className="mr-1">SEO</Link>
                                                      <Link to={"#"} className="mr-1">WordPress</Link>
                                                      <Link to={"#"} className="mr-1">Development</Link>
                                                      <Link to={"#"} className="mr-1">Joomla</Link>
                                                   </div>
                                                </div>
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}

                                    {/* <Accordion >
                                       <div className="panel">
                                          <div className="acod-head">
                                             <h5 className="acod-title">
                                                <Accordion.Toggle as={Link} variant="link" className="collapsed" eventKey="6">Features</Accordion.Toggle>
                                             </h5>
                                          </div>
                                          <Accordion.Collapse eventKey="6">
                                             <div className="acod-content">
                                                {widgetsBox.map((item, index) => (
                                                   <div className="item-widgets-box" key={index}>
                                                      <div className="item-widgets-left">
                                                         <img src={item.image} alt="" />
                                                      </div>
                                                      <div className="item-widgets-body text-black">
                                                         <h6 className="item-title text-uppercase font-weight-500 m-t0">
                                                            <Link to={"#"}>Product Title</Link>
                                                         </h6>
                                                         <ul className="item-review text-yellow-light">
                                                            <li><i className="fa fa-star mr-1"></i></li>
                                                            <li><i className="fa fa-star mr-1"></i></li>
                                                            <li><i className="fa fa-star mr-1"></i></li>
                                                            <li><i className="fa fa-star-half-o mr-1"></i></li>
                                                            <li><i className="fa fa-star-o mr-1"></i></li>
                                                         </ul>
                                                         <h6 className="item-price m-b0"><del>$232</del> <span className="text-primary">$192</span></h6>
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          </Accordion.Collapse>
                                       </div>
                                    </Accordion> */}
                                 </div>
                              </div>
                           </aside>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12 ">
                           {
                              this.state.isLoadingProduct && <Spinner typeSpinner="bubble-scale" />
                           }
                           <div className="row">
                              {dataFilterProduct.length > 0 ? dataFilterProduct.map((data, index) => (
                                 <ItemListProduct productName={data.name} productImage={data.image_thumbnail} cta={data.cta} keyName={data.slug} data={data} />
                              )) :
                                 dataProduct.map((data, index) => (
                                    <ItemListProduct productName={data.name} productImage={data.image_thumbnail} cta={data.cta} keyName={data.slug} data={data} />
                                 ))
                              }
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* <!-- Product END --> */}
               </div>
               <OrderService />
            </div>

            <Footer />
         </>
      )
   }
}
export default Product;