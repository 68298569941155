import { Link } from 'react-router-dom';

const iconBlog = [
   {
      icon: <i className="fa fa-gift" />, title: 'Source From Indonesia',
      para: 'We enable Indonesia`s natural resources to drive growth and expand their presence globally.',
   },
   {
      icon: <i className="fa fa-plane" />, title: 'Worldwide Delivery',
      para: 'We ship to all countries and to all continents of the world.',
   },
   {
      icon: <i className="fa fa-history" />, title: 'Sustainable Cooperation',
      para: 'Ability to work together for the long term, to build a successful business. ',
   },
];
function OrderService() {
   return (
      <>
         <div className="section-full p-t50 p-b20 bg-primary-dark text-white shop-action">
            <div className="container">
               <div className="row">
                  {iconBlog.map((data, index) => (
                     <div className="col-lg-4 col-md-4 col-sm-4" key={index}>
                        <div className="icon-bx-wraper left m-b30">
                           <div className="icon-md text-black radius">
                              <Link to={"#"} className="icon-cell text-white">{data.icon}</Link>
                           </div>
                           <div className="icon-content">
                              <h5 className="dlab-tilte">{data.title}</h5>
                              <p>{data.para}</p>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </>
   )
}
export { OrderService };