import { SupabaseClient } from "./db"

export const GetAllCategoryProduct = async () => {
   const { data, error } = await SupabaseClient
      .from('product_categories')
      .select().eq('is_published', true)

   return data
}
export const GetRecommedationProducts = async () => {
   const { data, error } = await SupabaseClient
      .from('products')
      .select(`
          id,
         slug,
         name,
         description,
         additional_information,
         cta,
         image_thumbnail,
         is_recommendation
         `).match({ is_published: true, is_recommendation: true })

   return data
}

export const GetAllProducts = async () => {
   const { data, error } = await SupabaseClient
      .from('products')
      .select().eq('is_published', true)

   return data
}

export const GetProductDetail = async (key) => {
   const images = []
   let dataProduct = []
   await SupabaseClient
      .from('products')
      .select(`
         id,
         slug,
         name,
         description,
         additional_information,
         cta,
         image_thumbnail,
         is_recommendation,
         product_categories(
            id,
            title
         )`
      ).eq('slug', key).then(async (res) => {
         // try {
         dataProduct = res.data[0]
         if (dataProduct) {
            const imgs = await SupabaseClient
               .from('image_product')
               .select(
                  `url`
               ).eq('product_id', dataProduct?.id)

            if (imgs?.data && imgs?.data?.length > 0) {
               imgs?.data?.forEach(dt => {
                  images.push(dt.url)
               });
            }
            dataProduct.images = images
         }

      }
      ).catch((err) => console.log(err))

   return dataProduct
}

export const GetContent = async (key) => {
   let dataContent = []
   await SupabaseClient
      .from('contents')
      .select().match({ key: key }).then(async (res) => {
         dataContent = res.data[0].content
      }
      ).catch((err) => console.log(err))
   // console.log("dataContent", data[0])

   return dataContent
}