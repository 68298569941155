import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Header from './../../Layout/Header';
// import Footer from './../../Layout/Footer';
// import PageTitle from './../../Layout/PageTitle';
// import { OrderDetail } from './../Login';
//Images
// import bnr from './../../../assets/images/banner/bnr1.jpg';
import { SetEvent } from '../../utils/analitycs/google-analitycs';
import { CTAInquiryUrl } from '../../utils/cta/cta';


class ItemProduct extends Component {
   render() {
      const { productName, productImage, cta, keyName } = this.props;

      return (
         <>
            {/* <Header /> */}
            {/* <div className="page-content bg-white"> */}
            {/* <!-- inner page banner --> */}
            {/* <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
                  <PageTitle motherMenu="Shop Columns" activeMenu="Shop Columns" />
               </div> */}
            {/* <!-- inner page banner END --> */}
            {/* <!-- contact area --> */}
            {/* <div className="section-full content-inner"> */}
            {/* <!-- Product --> */}
            {/* <div className="container"> */}
            {/* <div className="row"> */}
            {/* {itemBox.map((data, index) => ( */}
            <div className="item-products col-lg-3 col-md-6 col-sm-6">
               <div className="item-box m-b10">
                  <div className="item-img">
                     <img src={productImage} alt={productName} className='item-product-img' />
                     <div className="item-info-in center">
                        <ul className="h-100">
                           {/* <li><Link to={"#"}><i className="ti-shopping-cart"></i></Link></li> */}
                           <li><Link to={`/product/${keyName}`}
                              onClick={() => SetEvent('Product', 'Click', productName)}
                           ><i className="ti-eye mr-2"></i></Link></li>
                           <li><a href={CTAInquiryUrl(cta)} onClick={() => SetEvent('inquiry', 'direct_whatsapp', productName)}><i className="ti-headphone-alt ml-2"></i></a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="item-info text-center text-black p-a10">
                     <h6 className="item-title font-weight-500"><Link to={`/product/${keyName}`}
                        onClick={() => SetEvent('Product', 'Click', productName)}>{productName}</Link></h6>
                     {/* <ul className="item-review">
                                 <li><i className="fa fa-star mr-1"></i></li>
                                 <li><i className="fa fa-star mr-1"></i></li>
                                 <li><i className="fa fa-star mr-1"></i></li>
                                 <li><i className="fa fa-star-half-o mr-1"></i></li>
                                 <li><i className="fa fa-star-o"></i></li>
                              </ul>
                              <h4 className="item-price"><del>$232</del> <span className="text-primary">$192</span></h4> */}
                  </div>
               </div>
            </div>
            {/* ))} */}
            {/* </div> */}
            {/* </div> */}
            {/* <!-- Product END --> */}
            {/* </div> */}
            { /* <-- Icon Blog and Order detail--> */}
            {/* <OrderDetail /> */}
            {/* <-- Icon Blog and Order detail End--> */}
            {/* </div> */}

            {/* <Footer /> */}
         </>
      )
   }
}
export default ItemProduct;