const {
   REACT_APP_STAGE,
   REACT_APP_SUPABASE_KEY,
   REACT_APP_SUPABASE_URL,
   REACT_APP_GOOGLE_ANALITYCS_ID,
} = process.env;

const AppStage = REACT_APP_STAGE ? REACT_APP_STAGE : 'production'
const SupabaseUrl = REACT_APP_SUPABASE_URL ?? REACT_APP_SUPABASE_URL
const SupabaseKey = REACT_APP_SUPABASE_KEY ?? REACT_APP_SUPABASE_KEY
const GoogleAnalitycsID = REACT_APP_GOOGLE_ANALITYCS_ID ?? REACT_APP_GOOGLE_ANALITYCS_ID



export default {
   AppStage,
   SupabaseUrl,
   SupabaseKey,
   GoogleAnalitycsID
};