import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import bg5 from '../../../assets/images/background/bg5.jpg';
import Header from '../../Layout/Header';


const Error404 = () => {
	let resizeMargin = () => {
		var bgImag = "background-image: url(" + bg5 + ");";
		var screenHeight = window.innerHeight;
		document.getElementsByClassName('full-height')[0]?.setAttribute("style", bgImag + 'height:' + screenHeight + 'px;');
	};

	useEffect(() => {
		resizeMargin();
		window.addEventListener('resize', resizeMargin);
	}, []);
	return (
		<>
			<Header
				title="Arstnesia"
			/>
			<div className="dlab-bnr-inr full-height overlay-black-dark" style={{ backgroundImage: "url(" + bg5 + ")" }}>
				<div className="container">
					<div className="dlab-bnr-inr-entry align-m">
						<div className="row max-w700 dz_error-404 text-white m-auto">
							<div className="col-lg-4 m-tb30">
								<div className="dz_error" style={{ backgroundColor: '#6cc000', color: '#FFF5E7' }}>404</div>
							</div>
							<div className="col-lg-8 m-b30">
								<h2 className="error-head" style={{ color: "#FFF5E7" }}>Page Not Found !</h2>
								<p className="font-16">
									It looks like the page you're searching for isn’t available right now. But don't worry, we're on it and will have it sorted out soon.
								</p>
								<p className="font-16">In the meantime, feel free to return to the main page by clicking the button below.</p>
								<Link to={"./"} className="site-button">Go To Home</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

}
export default Error404;
