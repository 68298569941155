import React, { Fragment, Component } from 'react';
import CountUp from 'react-countup';

// const counterBlog = [
// 	{ icon: <i className="fa fa-briefcase m-r10" />, num: '15', title: 'Business Partner', },
// 	{ icon: <i className="fa fa-leaf m-r10" />, num: '10', title: 'Organic Products', },
// 	{ icon: <i className="fa fa-cubes m-r10" />, num: '15', title: 'Product Variant', },
// 	{ icon: <i className="fa fa-male m-r10" />, num: '10', title: 'Farmer', },
// ];

const Counter = ({ counterData }) => {

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row choses-info-content">
					{counterData?.map((data, index) => (
						<div className="col-6 col-lg-3 col-md-3 col-sm-6 p-a30">
							<h2 className="m-t0 m-b10 font-weight-400 font-45"><i className={`fa ${data.icon} m-r10`} /><span className="counter"><CountUp end={data.num} duration={4} /></span>+</h2>
							<h4 className="font-weight-300 m-t0">{data.title}</h4>
						</div>
					))}
				</div>
			</div>
		</Fragment>
	)
}
export default Counter;